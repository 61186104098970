import { createRef } from "react";
import "./App.scss";
import { CallBlock } from "./sections/CallBlock/CallBlock";
import { Case } from "./sections/Case/Case";
import { FAQ } from "./sections/FAQ/FAQ";
import { Footer } from "./sections/Footer/Footer";
import { FormSection } from "./sections/FormSection/FormSection";
import { Header } from "./sections/Header/Header";
import { Introduction } from "./sections/Introduction/Introduction";
import { NegativeMotivation } from "./sections/NegativeMotivation/NegativeMotivation";
import { Offer } from "./sections/Offer/Offer";
import { ProgrammingValue } from "./sections/ProgrammingValue/ProgrammingValue";
import { Sessions } from "./sections/Sessions/Sessions";

export const App = () => {
  const formSectionRef = createRef<HTMLElement>();

  const scrollToForm = () => {
    formSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onCTA1Click = () => {
    gtag("event", "cta_pressed", {
      id: "1",
    });
    scrollToForm();
  };

  const onCTA2Click = () => {
    gtag("event", "cta_pressed", {
      id: "2",
    });
    scrollToForm();
  };

  return (
    <>
      <Header />
      <Introduction />
      <Offer onCTAClick={onCTA1Click} />
      <ProgrammingValue />
      <NegativeMotivation />
      <CallBlock onCTAClick={onCTA2Click} />
      {/* <Sessions /> */}
      <Case />
      <FormSection ref={formSectionRef} />
      <FAQ />
      <Footer />
    </>
  );
};
