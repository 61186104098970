import { Heading1 } from "../../UI/Heading1/Heading1";
import { Section } from "../../UI/Section/Section";
import "./FAQ.scss";
import { Question } from "./Question/Question";

export const FAQ = () => {
  return (
    <Section>
      <Heading1 color="primary">FAQ</Heading1>
      <Question
        question="Zvládnu to při studiu / práci?"
        answer="Krátce - ano. Samozřejmě, čím víc času tomu dáš, tím líp, ale třeba 15 hodin týdně si najde snad každý a ono to stačí. I 10 hodin stačí, záleží, jak rychle se tam chceš dostat."
      />
      <Question
        question="Je to pro mě?"
        answer="Jestli máš logické myšlení, tak ano. Nepotřebuješ ani matiku, ani fyziku. Potřebuješ jen základní angličtinu."
      />
    </Section>
  );
};
