import { FC, ReactNode } from "react";
import "./CTA.scss";

interface CTAProps {
  children: ReactNode;
  onClick: () => any;
}

export const CTA: FC<CTAProps> = ({ children, onClick }) => {
  return (
    <button className="cta" onClick={onClick}>
      {children}
    </button>
  );
};
