import { BulletText } from "../../UI/BulletText/BulletText";
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Section } from "../../UI/Section/Section";
import "./NegativeMotivation.scss";

export const NegativeMotivation = () => {
  return (
    <Section className="negativeMotivation">
      <Heading1 color="danger">
        Co se stane, když se budeš učit špatně?
      </Heading1>
      <div className="negativeMotivation__content">
        <div className="negativeMotivation__content__left">
          <BulletText
            type="cross"
            heading="Ztratíš se v množství informací a vyhoříš"
          />
          <BulletText type="cross" heading="Budeš se učit nedůležité věci" />
          <BulletText
            type="cross"
            heading="Ujde ti čas = ujdou ti peníze = ujdou ti sny"
          />
        </div>
        <div className="negativeMotivation__content__right">
          <BulletText
            type="cross"
            heading="Utratíš moc peněz za špatné studijní materiály"
          />
          <BulletText
            type="cross"
            heading="V práci tě odmítnou, protože nebudeš umět co potřebují"
          />
          <BulletText type="cross" heading="Zůstaneš tam, kde jsi teď" />
        </div>
      </div>
    </Section>
  );
};
