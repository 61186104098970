import { FC } from 'react';
import { Heading3 } from '../../../UI/Heading3/Heading3';
import { Paragraph } from '../../../UI/Paragraph/Paragraph';
import './Question.scss';

interface QuestionProps {
  question: string;
  answer: string;
}

export const Question: FC<QuestionProps> = ({ question, answer }) => {
  return (
    <div className='question'>
      <Heading3>{question}</Heading3>
      <Paragraph>{answer}</Paragraph>
    </div>
  )
}
