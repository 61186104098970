import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import "./Header.scss";

export const Header = () => {
  return (
    <header className="header">
      <Heading2 color="light">CodeCoach</Heading2>
      <Heading1>
        Provedu tě od začátečníka k profi programátorovi, co je finančně, časově
        i lokačně svobodný.
      </Heading1>
      <Heading1 color="primary">
        První sezení {"("}první pomoc{")"} úplně zdarma!
      </Heading1>
    </header>
  );
};
