import { FC } from "react";
import { CTA } from "../../UI/CTA/CTA";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Section } from "../../UI/Section/Section";
import "./CallBlock.scss";

interface CallBlockProps {
  onCTAClick: () => any;
}

export const CallBlock: FC<CallBlockProps> = ({ onCTAClick }) => {
  return (
    <Section className="callBlock">
      <Paragraph align="center">
        <strong>Připraven?</strong> Na první půlhodinové schůzce zdarma
        vymyslíme, jestli a jak na to.{" "}
        <strong>
          Odejdeš z ní se vším, na co se stihneš zeptat, co tě o učení
          programování zajímalo. 🤓
        </strong>
      </Paragraph>
      <CTA onClick={onCTAClick}>PŘIPRAVEN! LET'S DO IT</CTA>
    </Section>
  );
};
