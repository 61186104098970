import { ChangeEventHandler, forwardRef, useState } from "react";
import { REGEX_EMAIL } from "../../constants";
import { CTA } from "../../UI/CTA/CTA";
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading3 } from "../../UI/Heading3/Heading3";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Popup } from "../../UI/Popup/Popup";
import { Section } from "../../UI/Section/Section";
import "./FormSection.scss";

export const FormSection = forwardRef<HTMLElement>((_props, ref) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [note, setNote] = useState("");

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    validateName(e.target.value);
  };

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const onDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDate(e.target.value);
    validateDate(e.target.value);
  };

  const onNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setNote(e.target.value);
  };

  const validateName = (name: string) => {
    if (!name) {
      setIsNameValid(false);
      return false;
    }
    setIsNameValid(true);
    return true;
  };

  const validateEmail = (email: string) => {
    if (!email || !REGEX_EMAIL.test(email.toLowerCase())) {
      setIsEmailValid(false);
      return false;
    }
    setIsEmailValid(true);
    return true;
  };

  const validateDate = (date: string) => {
    if (!date) {
      setIsDateValid(false);
      return false;
    }
    setIsDateValid(true);
    return true;
  };

  const validate = () => {
    let valid = true;

    if (!validateName(name)) {
      valid = false;
    }
    if (!validateEmail(email)) {
      valid = false;
    }
    if (!validateDate(date)) {
      valid = false;
    }

    return valid;
  };

  const cleanInputs = () => {
    setName("");
    setEmail("");
    setNote("");
    setDate("");
    setIsNameValid(true);
    setIsEmailValid(true);
    setIsDateValid(true);
  };

  const onSubmit = () => {
    if (!validate()) return;
    setIsLoading(true);
    setIsPopupOpen(true);

    gtag("event", "free_session_registered");
    fbq("track", "Lead");

    const data = {
      message: note,
      email: email,
      date: date,
      name: name,
    };

    fetch(
      "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-5aa94dd0-9819-41e0-8bea-e3efb0faddcb/amniomweb/index/codecoach",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        setIsLoading(false);
        if (!res.ok) {
          throw new Error("Not ok");
        }
        return res.json();
      })
      .then((json) => {
        if (!json.success) {
          throw new Error("Mail not sent");
        }
        cleanInputs();
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const dismissPopup = () => {
    setIsPopupOpen(false);
    setIsLoading(false);
    setIsError(false);
  };

  return (
    <Section className="form" ref={ref}>
      {isPopupOpen && (
        <Popup
          text={
            isLoading
              ? "ODESÍLÁNÍ..."
              : isError
              ? "Něco se nepovedlo, zkuste to za chvíli znovu 😞"
              : "E-mail mi došel, ozvu se hned jak to půjde 🙏"
          }
          onDismiss={dismissPopup}
          isLocked={isLoading}
        />
      )}
      <Heading1 color="primary">
        Zjisti, jaký máš možnosti, zeptej se, na co chceš (zdarma 🤫)
      </Heading1>
      <Paragraph size="regular" align="center">
        Sejdeme se online (google meet, případně co ti vyhovuje). První call
        zdarma má půl hodiny. Kdykoli potom budeš potřebovat se můžeme domluvit
        na další sezení. Ty můžou mít až dvě hodiny.
      </Paragraph>
      <Paragraph size="regular" align="center">
        Můžeš využít formulář, nebo mi zavolat na{" "}
        <a href="tel:+420735044114">735 044 114</a> nebo napsat na{" "}
        <a href="mailto:pepa@codecoach.cz">pepa@codecoach.cz</a>.
      </Paragraph>
      <div className="form__body">
        <form className="form__form">
          <div className="form__inputWrapper">
            <label htmlFor="name">
              Jméno<span>*</span>
            </label>
            <input
              value={name}
              onChange={onNameChange}
              id="name"
              className={!isNameValid ? "invalid" : ""}
            />
          </div>
          <div className="form__inputWrapper">
            <label htmlFor="email">
              E-mail<span>*</span>
            </label>
            <input
              value={email}
              onChange={onEmailChange}
              id="email"
              className={!isEmailValid ? "invalid" : ""}
            />
          </div>
          <div className="form__inputWrapper">
            <label htmlFor="date">
              Preferovaný termín (datum a čas)<span>*</span>
            </label>
            <input
              value={date}
              onChange={onDateChange}
              id="date"
              className={!isDateValid ? "invalid" : ""}
            />
          </div>
          <div className="form__inputWrapper">
            <label htmlFor="note">Poznámka</label>
            <textarea value={note} onChange={onNoteChange} id="note" />
          </div>
        </form>
        <CTA onClick={onSubmit}>JDU DO TOHO, NENÍ CO ZTRATIT</CTA>
        <Heading3 align="center">Těším se na tvoje výsledky!</Heading3>
        <Paragraph align="center">
          P.S.: Jestli jsi se chceš posunout, rezervuj si to hned. Znáš se,
          necháš to na později a už se k tomu nevrátíš. 😀 (Ne, že bych byl
          jinej)
        </Paragraph>
      </div>
    </Section>
  );
});
