import { FC, ReactNode } from "react";
import checkmark from "../../assets/checkmark.svg";
import crossmark from "../../assets/crossmark.svg";
import { Heading3, Heading3Props } from '../Heading3/Heading3';
import { Paragraph } from '../Paragraph/Paragraph';
import "./BulletText.scss";

interface BulletTextProps {
  children?: ReactNode;
  type: "cross" | "check";
  heading: string;
  headingColor?: Heading3Props['color'];
  className?: string;
}

export const BulletText: FC<BulletTextProps> = ({ children, type, heading, headingColor, className }) => {
  let markSrc = checkmark;
  if (type === "cross") {
    markSrc = crossmark;
  }

  let bulletTextClassName = `bulletText ${className}`;
  if (children) {
    bulletTextClassName += ' bulletText--alignTop'
  }

  return (
    <li className={bulletTextClassName}>
      <img src={markSrc} alt="Odrážka" />
      <div className="bulletText__content">
        <Heading3 color={headingColor}>{heading}</Heading3>
        {children && <Paragraph size='small'>{children}</Paragraph>}
      </div>
    </li>
  );
};
