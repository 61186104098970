import { Heading1 } from "../../UI/Heading1/Heading1";
import { Section } from "../../UI/Section/Section";
import "./Case.scss";
import case600 from "../../assets/case-600.jpg";
import { Paragraph } from "../../UI/Paragraph/Paragraph";

export const Case = () => {
  return (
    <Section className="case">
      <Heading1 color="primary" align="center">Kuba už je za vodou</Heading1>
      <img src={case600} alt="Kuba už je za vodou" />
      <Paragraph align="center">
        Jakub Voženílek je můj kamarád, kterého jsem na jeho programátorské
        cestě taky provázel. Nedávno si dokonce nainstaloval Linux (viz fotka)
        😀. Jak sám říká, <strong>dřív byl taky mezi slabšími</strong>, když na
        střední programovali. Co dokáže teď je neuvěřitelný. Začal se zajímat o
        creative coding a chce jít na kyberbezpečnost, až udělá maturitu.{" "}
        <strong>Peníze řešit nemusí</strong>, má zakázky, tak programuje.
      </Paragraph>
    </Section>
  );
};
