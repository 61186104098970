import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Section } from "../../UI/Section/Section";
import "./Introduction.scss";
import meAtWork600 from "../../assets/me-at-work-600.jpg";
import labeledIncomeImg from "../../assets/labeled-income.png";

export const Introduction = () => {
  return (
    <Section className="introduction">
      <div className="introduction__row introduction__row--1">
        <div className="introduction__content">
          <Paragraph>
            Než se Jirka naučil programovat, jeho nejlepší vyhlídky byly několik
            let studovat, a pak pracovat za plat, který by mu asi stejně
            nestačil. Teď, jako programátor samouk, má konečně dostatek a je za
            to hrozně vděčný.
          </Paragraph>
          <Paragraph>
            Může pracovat odkudkoli, za... dobrý peníze (ty hlavně investuje), v
            práci má tolik výhod, že by se mi to sem nevešlo. Přitom se stačilo
            naučit správnou dovednost.
          </Paragraph>
          <Paragraph>
            Pořád nemít dost peněz, muset se učit věci, co stejně nepoužije a
            doufat, že ho třeba někde zaměstnají, je pro něj děsivá představa.
          </Paragraph>
        </div>
        <img src={meAtWork600} alt="Já v práci" />
      </div>
      <div className="introduction__quote">
        “A to nikdy předtím neprogramoval. Jen na střední, kde zpravidla končil
        se zadáním poslední.”
      </div>
      <div className="introduction__row introduction__row--2">
        <img src={labeledIncomeImg} alt="Nárůst příjmů" />
        <div className="introduction__content">
          <Paragraph>
            Být samouk ale není úplně jednoduchý - zvlášť, když chceme co
            nejrychleji získat práci. Sám jsem kdysi nevěděl, co po mně opravdu
            budou v práci chtít, jaký technologie je dobrý umět a upřímně jsem
            skoro vyhořel.
          </Paragraph>
          <Paragraph>
            Naštěstí jsem měl skvělou intuici na to, co se učit dál, co a kdy
            procvičovat, jak se učit, kde hledat materiály, a pak i na které
            pracovní nabídky odpovědět.
          </Paragraph>
          <Paragraph>
            Teď i v zaměstnání vidím, jaký jsou minimální znalosti, kdo se v
            práci udrží, na co je dobrý se připravit předem... A říkám si, že je
            škoda si tyhle informace nechávat pro sebe.
          </Paragraph>
        </div>
      </div>
    </Section>
  );
};
