import { Section } from "../../UI/Section/Section";
import "./ProgrammingValue.scss";

export const ProgrammingValue = () => {
  return (
    <Section>
      <p className="programmingValue">
        Programování je jeden z <strong>vysoce hodnotných skillů</strong>.
        Otevírá dveře <strong>vyššímu lifestylu</strong>,{" "}
        <strong>prestižní práci</strong> kde <strong>můžeš růst</strong>, můžeš
        pracovat klidně <strong>z Havaje</strong>, můžeš pracovat{" "}
        <strong>v zahraničí</strong>, firmy ti <strong>utrhnou ruce</strong> a
        nabídnou <strong>bohaté benefity</strong>. Nebo můžeš{" "}
        <strong>pracovat sám na sebe</strong> jako freelancer,{" "}
        <strong>založit si firmu</strong> nebo investovat a{" "}
        <strong>předčasně odejít do důchodu</strong>.
      </p>
    </Section>
  );
};
