import { FC } from "react";
import { BulletText } from "../../UI/BulletText/BulletText";
import { CTA } from "../../UI/CTA/CTA";
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Section } from "../../UI/Section/Section";
import "./Offer.scss";

interface OfferProps {
  onCTAClick: () => any;
}

export const Offer: FC<OfferProps> = ({ onCTAClick }) => {
  return (
    <Section className="offer">
      <Heading1 color="primary">
        Co se učit? Jak se učit? Jak začít? Z čeho se učit? ... 😩
      </Heading1>
      <div className="offer__main">
        <BulletText type="check" heading="Odpovědi na všechny tvoje otázky">
          Co tě čeká na cestě? Je vůbec pro tebe? Jak dlouho to bude trvat? Jsou
          všichni programátoři divní? Prostě zkusím odpovědět na cokoli tě
          zajímá.
        </BulletText>
        <BulletText type="check" heading="Pomoc s výběrem studijních materiálů">
          Neztratit se ve výběru zdrojů a najít opravdu ty nejefektivnější, abys
          neplýtval čas - to je můj hlavní focus.
        </BulletText>
        <BulletText type="check" heading="Pomoc s výběrem zaměření">
          A s tím, jestli se do něčeho ponořit víc, nebo se radši učit jinou
          věc.
        </BulletText>
        <BulletText
          type="check"
          heading="Efektivní roadmapa pro dosažení cílů"
          headingColor="danger"
        >
          Ze začátku toho fakt není málo 😃 Kdybych měl nějaký efektivní přehled
          technologií, co musím znát, neplýtval bych čas těmi, co znát nemusím.
        </BulletText>
      </div>
      <div className="offer__secondary">
        <BulletText
          type="check"
          heading="Pomoc s technikami učení programování"
        />
        <BulletText type="check" heading="Pomoc s napsáním životopisu" />
        <BulletText
          type="check"
          heading="Interview nanečisto a vyhodnocení skills"
        />
        <BulletText
          type="check"
          heading="Někdo, s kým to prostě můžeš probrat"
        />
        <BulletText type="check" heading="Pomoc s timemanagementem" />
        <BulletText type="check" heading="Pomoc s hledáním ideální práce" />
        <BulletText
          type="check"
          heading="Pomoc vlastně s čímkoli na cestě"
          className="offer__bulletText__column2"
        />
      </div>
      <CTA onClick={onCTAClick}>TO SE MI HODÍ! (POJĎME SI ZAVOLAT)</CTA>
    </Section>
  );
};
