import "./Footer.scss";
import terms from "../../assets/terms.pdf";

export const Footer = () => {
  return (
    <footer className="footer">
      <a href={terms} target="_blank" rel="noreferrer">
        Obchodní podmínky, ochrana údajů
      </a>{" "}
      | © {new Date().getFullYear()} Josef Macků (IČO 09212884) | Vytvořil{" "}
      <a href="https://amniomweb.cz" target="_blank" rel="noreferrer">
        AmnioM Web
      </a>
    </footer>
  );
};
